.App {
  background-color: #232527;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2.5vmin);
  color: white;
}

.title {
  margin-bottom: 5px;
  color: white;
}
